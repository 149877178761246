import search from "./search";

const searchMultiple = (dataArray, searchObj) => {
  let filteredArray = dataArray;
  for (const [key, value] of Object.entries(searchObj)) {
    filteredArray = search(filteredArray, value, key);
  }
  return filteredArray;
};

export default searchMultiple;
