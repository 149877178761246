import React from "react";
import { Router } from "@reach/router";

import AvailableNodesListingPage from "../components/PageComponents/AvailableNodesListingPage";

const AvailableNodes = (props) => (
  <Router>
    <AvailableNodesListingPage {...props} path="/availableNodes" />
  </Router>
);

export default AvailableNodes;
